<template>
  <el-dialog
    width="25rem"
    visible
    class="add-membership-modal"
    :title="$t('memberships.membershipTable.addEditMembershipModal.title')"
    @close="$emit('on-close')"
  >
    <el-form ref="form" :model="addEditMembershipForm" label-position="top" :rules="rules">
      <el-form-item required>
        <div class="name-input">
          <el-form-item
            prop="firstName"
            :label="$t('memberships.membershipTable.addEditMembershipModal.form.firstName')"
          >
            <el-input v-model="addEditMembershipForm.firstName" :disabled="isEditMode"></el-input>
          </el-form-item>
          <el-form-item prop="lastName" :label="$t('memberships.membershipTable.addEditMembershipModal.form.lastName')">
            <el-input v-model="addEditMembershipForm.lastName" :disabled="isEditMode"></el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item prop="email" :label="$t('memberships.membershipTable.addEditMembershipModal.form.email')">
        <el-input v-model="addEditMembershipForm.email" :disabled="isEditMode" />
      </el-form-item>
      <el-form-item prop="role" :label="$t('memberships.membershipTable.addEditMembershipModal.form.role')">
        <MembershipRoleDropdown
          :value="addEditMembershipForm.role"
          :business-type="businessType"
          :allowed-roles="allowedRoles"
          @on-role-change="(newValue) => (addEditMembershipForm.role = newValue)"
        />
      </el-form-item>
    </el-form>

    <div v-if="!isEditMode" class="information">
      <NewInformationIcon :size="16" fill="#94989F" />
      <p>{{ $t('memberships.membershipTable.addEditMembershipModal.info') }}</p>
    </div>

    <template #footer>
      <Button type="secondary" @click="$emit('on-close')">{{
        $t('memberships.membershipTable.addEditMembershipModal.cancel')
      }}</Button>
      <Button :loading="loadingAdd" @click="onSubmit">{{
        $t(`memberships.membershipTable.addEditMembershipModal.${isEditMode ? 'confirmEdit' : 'confirm'}`)
      }}</Button>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, getCurrentInstance, computed, ref, watch } from 'vue';
import { Button } from '@/modules/core';
import { ROLE_TYPE } from '@/modules/users/constants';
import { NewInformationIcon } from '@/assets/icons';
import { useTenancy, useUserPermissions } from '@/modules/auth';

import { INVITE_PERMISSIONS } from '../constants';
import MembershipRoleDropdown from './MembershipRoleDropdown';

export default {
  name: 'AddEditMembershipModal',
  components: { Button, NewInformationIcon, MembershipRoleDropdown },
  props: {
    loadingAdd: { type: Boolean, required: true },
    allowedRoles: { type: Array, required: true },
    editIndex: { type: Number, default: null },
    memberships: { type: Array, default: null },
  },
  emits: ['on-create-membership', 'on-close'],
  setup({ editIndex, memberships }, { emit }) {
    const root = getCurrentInstance().proxy;
    const form = ref(null);
    const addEditMembershipForm = reactive({ firstName: null, lastName: null, email: null, role: null });

    const { isUserPermittedForActiveTenantNonReactive } = useUserPermissions();

    const { currentTenant } = useTenancy();

    const isEditMode = computed(() => editIndex !== null);
    const businessType = computed(() => currentTenant.value?.type);

    const setFormValues = ({ firstName, lastName, email, role }) => {
      addEditMembershipForm.firstName = firstName;
      addEditMembershipForm.lastName = lastName;
      addEditMembershipForm.email = email;
      addEditMembershipForm.role = role;
    };

    const resetFormValues = () => {
      setFormValues({ firstName: null, lastName: null, email: null, role: null });
    };

    const validateEmail = (_, email, callback) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(email)) {
        callback();
      } else {
        callback(new Error());
      }
    };

    watch(
      () => [editIndex, memberships],
      () => {
        if (isEditMode.value && memberships && memberships[editIndex]) {
          const { firstName, lastName, email } = memberships[editIndex].user;
          const { role } = memberships[editIndex];

          setFormValues({ firstName, lastName, email, role });
        } else {
          resetFormValues();
        }
      },
      { immediate: true, deep: true }
    );

    const formRules = {
      firstName: [
        {
          required: !isEditMode.value,
          message: root.$t('memberships.membershipTable.addEditMembershipModal.form.errors.firstName'),
          trigger: 'change',
        },
      ],
      lastName: [
        {
          required: !isEditMode.value,
          message: root.$t('memberships.membershipTable.addEditMembershipModal.form.errors.lastName'),
          trigger: 'change',
        },
      ],
      email: [
        {
          required: !isEditMode.value,
          message: root.$t('memberships.membershipTable.addEditMembershipModal.form.errors.missingEmail'),
          trigger: 'change',
        },
        {
          validator: validateEmail,
          message: root.$t('memberships.membershipTable.addEditMembershipModal.form.errors.invalidEmail'),
          trigger: 'change',
        },
      ],
      role: [
        {
          required: true,
          message: root.$t('memberships.membershipTable.addEditMembershipModal.form.errors.role'),
          trigger: 'change',
        },
      ],
    };

    const onSubmit = () => {
      form.value.validate(async (valid) => {
        if (valid) {
          emit('on-add-edit-membership', { ...addEditMembershipForm, editIndex });
        }
      });
    };

    const hasPermissionToInviteRole = (role) => {
      switch (role.toUpperCase()) {
        case ROLE_TYPE.ORDERER:
        case ROLE_TYPE.PURCHASE_MANAGER:
          return isUserPermittedForActiveTenantNonReactive(INVITE_PERMISSIONS.pm_orderer);
        case ROLE_TYPE.MANAGER:
          return isUserPermittedForActiveTenantNonReactive(INVITE_PERMISSIONS.manager);
        case ROLE_TYPE.PAYMENTS_MANAGER:
          return isUserPermittedForActiveTenantNonReactive(INVITE_PERMISSIONS.payments_manager);
        case ROLE_TYPE.OWNER:
          return isUserPermittedForActiveTenantNonReactive(INVITE_PERMISSIONS.owner);
        default:
          return false;
      }
    };

    const handleItemMouseEnter = (event) => {
      event.currentTarget.classList.add('hover');
    };

    const handleItemMouseLeave = (event) => {
      event.currentTarget.classList.remove('hover');
    };

    return {
      addEditMembershipForm,
      rules: formRules,
      isEditMode,
      onSubmit,
      form,
      businessType,
      hasPermissionToInviteRole,
      handleItemMouseLeave,
      handleItemMouseEnter,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
::v-deep {
  .el-dialog {
    border-radius: 0.5rem;
  }
  .el-dialog__title {
    font-size: 1.25rem;
    font-weight: $font-weight-bolder;
  }

  .el-dialog__body {
    padding-top: 0.5rem;
  }

  .el-select-dropdown__item {
    &.hover {
      background-color: #f5f7fa;
    }
    &:hover {
      background-color: transparent !important;
    }
  }
}

.add-membership-modal {
  color: red;

  .information {
    display: flex;
    gap: 0.25rem;
    color: #71767d;
    align-items: center;

    p {
      word-break: break-word;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;

      [dir='ltr'] & {
        font-family: Inter;
      }

      [dir='rtl'] & {
        font-family: Rubik;
      }
    }
  }

  .role-disabled-item-content {
    [dir='ltr'] & .lock-icon {
      margin-left: auto;
      margin-right: 0;
    }

    [dir='rtl'] & .lock-icon {
      margin-right: auto;
      margin-left: 0;
    }
  }
}

.name-input {
  display: flex;
  gap: 1.5rem;
}
</style>
