<template>
  <div>
    <Table
      v-loading="relationsLoading"
      custom-class="relations-table"
      :data="tableData"
      :columns="columns"
      show-index
      rounded
      border
      hover
    >
      <template #cell-tenantName="{ rowData: { tenantName } }">
        <div class="d-flex align-items-center">
          <div class="shop-icon-wrapper bg-secondary rounded-pill mx-1">
            <ShopIcon class="shop-icon text-typography-secondary" />
          </div>
          <span>{{ tenantName }}</span>
        </div>
      </template>

      <template #cell-actions="{ rowIndex }">
        <el-dropdown
          v-if="isUserPermitted"
          class="d-flex justify-content-center"
          trigger="click"
          @command="(command) => handleAction(rowIndex)"
        >
          <Button :id="`actions-row-${rowIndex}`" type="icon" class="actions-btn text-typography-primary">
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item :command="ACTIONS.DELETE">
              <div class="action-delete d-flex align-items-center gap-2 ps-2">
                <UnlinkV2Icon />
                <p>{{ $t('relations.businessesTable.actions.delete') }}</p>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>

    <DeleteBusinessRelationModal
      v-if="showCloseRelationModal"
      :target-tenant="selectedTenant.tenantName"
      :source-tenant="sourceTenant"
      @submit="submit"
      @close="close"
    />
  </div>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { Table, Button } from '@/modules/core';
import { KebabIcon, UnlinkV2Icon, ShopIcon } from '@/assets/icons';
import DeleteBusinessRelationModal from './DeleteBusinessRelationModal';

const TABLE_HEADERS = {
  TENANT: 'tenantName',
  TYPE: 'permissionType',
  ACTIONS: 'actions',
};

const ACTIONS = {
  DELETE: 'delete',
};

export default {
  name: 'BusinessRelationsTable',
  components: { Table, KebabIcon, UnlinkV2Icon, Button, DeleteBusinessRelationModal, ShopIcon },
  props: {
    relationsLoading: { type: Boolean, default: false },
    relations: { type: Array, default: () => [] },
    sourceTenant: { type: String, default: '' },
    isUserPermitted: { type: Boolean, default: false },
  },
  emits: ['deleteRelation'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const showCloseRelationModal = ref(false);
    const selectedTenant = ref(null);

    const columns = [
      { header: root.$t(`relations.businessesTable.headers.${TABLE_HEADERS.TENANT}`), key: TABLE_HEADERS.TENANT },
      { header: root.$t(`relations.businessesTable.headers.${TABLE_HEADERS.TYPE}`), key: TABLE_HEADERS.TYPE },
      { header: '', key: TABLE_HEADERS.ACTIONS, width: '2.5rem', customClass: 'p-0 justify-content-end' },
    ];

    const tableData = computed(() => props.relations);

    const handleAction = async (rowIndex) => {
      selectedTenant.value = tableData.value[rowIndex];
      showCloseRelationModal.value = true;
    };

    const submit = () => {
      emit('deleteRelation', { id: selectedTenant.value.id });
      showCloseRelationModal.value = false;
    };
    const close = () => {
      showCloseRelationModal.value = false;
      selectedTenant.value = null;
    };

    return {
      tableData,
      ACTIONS,
      columns,
      handleAction,
      submit,
      close,
      showCloseRelationModal,
      selectedTenant,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.relations-table {
  tr {
    .shop-icon-wrapper {
      height: 32px;
      width: 32px;
      margin: 0 !important;
      margin-inline-end: 0.5rem !important;
      .shop-icon {
        display: block;
        margin: auto;
        margin-top: 4px;
      }
    }

    .actions-btn {
      visibility: hidden;
    }

    &:hover .actions-btn {
      visibility: visible;
    }
  }
}

.action-delete {
  color: #e52044;
}

::v-deep th:nth-of-type(2)div.header-text {
  margin-right: 5px;
}
</style>
