<template>
  <el-dialog
    width="25rem"
    visible
    class="relations-modal"
    :title="$t('relations.businessesTable.addPermissionModal.title')"
    @close="$emit('close')"
  >
    <div>
      <div class="warning-section d-flex justify-content-start">
        <p>{{ $t('relations.businessesTable.addPermissionModal.modalBodyText', { sourceTenant }) }}</p>
      </div>

      <div>
        <el-form ref="form" :model="relationsForm" label-position="top" :show-message="false">
          <el-form-item :label="$t('relations.businessesTable.addPermissionModal.dropdownText')" class="mb-3">
            <el-select v-model="relationsForm.tenant" class="w-100" filterable>
              <el-option
                v-for="business in Object.values(businesses)"
                :key="business.id"
                :label="business.name"
                :value="business.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <template #footer>
      <Button type="secondary" @click="$emit('close')">{{
        $t('relations.businessesTable.addPermissionModal.cancel')
      }}</Button>
      <Button @click="onSubmit">{{ $t('relations.businessesTable.addPermissionModal.confirm') }}</Button>
    </template>
  </el-dialog>
</template>

<script>
import { ref } from 'vue';
import { Button } from '@/modules/core';

export default {
  name: 'CreateBusinessRelationModal',
  components: { Button },
  props: {
    businesses: { type: Object, default: () => {} },
    sourceTenant: { type: String, default: '' },
  },
  emits: ['submit', 'close'],
  setup(props, { emit }) {
    const relationsForm = ref({ tenant: null });

    const onSubmit = async () => {
      const tenantId = relationsForm.value.tenant;
      emit('submit', { selectedTenant: props.businesses[tenantId] });
      emit('close');
    };

    return {
      relationsForm,
      onSubmit,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
.relations-modal {
  ::v-deep .el-dialog {
    border-radius: 0.5rem;
  }
  ::v-deep .el-dialog__title {
    font-size: 1.25rem;
    font-weight: $font-weight-bolder;
  }

  .warning-section {
    padding: 0.75rem;
    border-radius: 0.25rem;
    color: #b85c00;
    background: #f9f2e8;
    margin-bottom: 1rem;

    p {
      word-break: break-word;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;

      [dir='ltr'] & {
        font-family: Inter;
      }

      [dir='rtl'] & {
        font-family: Rubik;
      }
    }
  }
}
</style>
