<template>
  <div>
    <PageLayout>
      <template #header>
        <h1>{{ $t('relations.title') }}</h1>
      </template>
      <template #tabs>
        <Tabs :tabs="tabs" :active-tab.sync="activeTab" />
      </template>

      <div v-if="activeTab === 0">
        <div class="d-flex justify-content-end mb-4">
          <MembershipManagement />
        </div>
      </div>

      <div v-if="activeTab === 1">
        <BusinessRelations />
      </div>
    </PageLayout>
  </div>
</template>

<script type="text/javascript">
import { ref, getCurrentInstance } from 'vue';
import { PageLayout, Tabs } from '@/modules/core';
import BusinessRelations from './pages/BusinessRelations';
import MembershipManagement from './pages/MembershipManagement';

export default {
  components: { PageLayout, Tabs, BusinessRelations, MembershipManagement },
  setup() {
    const root = getCurrentInstance().proxy;
    const tabs = [{ text: root.$t('relations.usersPermissions') }, { text: root.$t('relations.tenantsPermissions') }];

    return {
      tabs,
      activeTab: ref(0),
    };
  },
};
</script>
