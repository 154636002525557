<template>
  <el-dialog width="25rem" visible class="relations-modal" @close="$emit('close')">
    <template #title>
      <span class="el-dialog__title">
        <ErrorFullIcon width="1.5rem" height="1.5rem" />
        {{ $t('relations.businessesTable.deletePermission.title') }}
      </span>
    </template>
    {{ $t('relations.businessesTable.deletePermission.text', { targetTenant, sourceTenant }) }}
    <template #footer>
      <Button type="secondary" @click="$emit('close')">{{
        $t('relations.businessesTable.deletePermission.cancel')
      }}</Button>
      <Button type="danger" @click="$emit('submit')">{{
        $t('relations.businessesTable.deletePermission.confirm')
      }}</Button>
    </template>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { ErrorFullIcon } from '@/assets/icons';

export default {
  name: 'DeleteBusinessRelationModal',
  components: { Button, ErrorFullIcon },
  props: {
    businesses: { type: Array, default: () => [] },
    currentTenant: { type: Object, default: () => ({}) },
    sourceTenant: { type: String, default: '' },
    targetTenant: { type: String, default: '' },
  },
  emits: ['submit', 'close'],
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
.relations-modal {
  ::v-deep .el-dialog {
    border-radius: 0.5rem;
  }
  ::v-deep .el-dialog__title {
    font-size: 1.25rem;
    font-weight: $font-weight-bolder;

    svg {
      margin-bottom: 3px;
    }
  }

  .warning-section {
    padding: 0.75rem;
    border-radius: 0.25rem;
    color: #b85c00;
    background: #f9f2e8;
    margin-bottom: 1rem;

    p {
      word-break: break-word;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;

      [dir='ltr'] & {
        font-family: Inter;
      }

      [dir='rtl'] & {
        font-family: Rubik;
      }
    }
  }
}
</style>
